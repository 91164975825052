@import url('../../../src/styles/tailwind.scss');

$primary-color: #FFD015;
$second-color: #000000;
$third-color: #111920;

$rgb-primary-color: 255, 8, 6; //--primary-color *
$rgb-second-color: 2, 7, 71; //--second-color *
$rgb-third-color: 34, 34, 34; //--third-color *

@import "../../../src/styles/vars.scss";
@import "../../../src/styles/styles.scss";

body.webapp-site {
    --font-title: 'Grunge Font', sans-serif;
    --font-subtext: 'Inter Tight', sans-serif;
    --font-text: 'Inter Tight', sans-serif;
    //background-color: var(--second-color);

    .img__bg-nf {
        background-image: url('/assets/img/figure.svg');
    }
    @media screen and (max-width: 767px) {
        .img__bg-nf {
            background-image: url('/assets/img/figure-resp.svg');
        }
    }
}
